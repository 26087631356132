/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import './Header.css'


const Header = () => {
  const localValue = localStorage.getItem("isLoggedIn");
  const handleLogout = () => {
    localStorage.clear();
    
  };
  return (
    <header className="">
      <div className="container">
        <nav>
          <a href="/" className="logo" style={{ fontSize: "30px" }}>
          
            {/* <img src="https://consultidil.hi.link/_assets/site-data/ops_ciBPcPuMTO3wGxcJmm/images/upl_kxKQCYqeiZGSgceCQH.png  " /> */}
            <img src="../../logo.png" />
          </a>
        </nav>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/about">À propos</a>
          </li>
          <li>
            <a href="/Publicevents"> Liste des Foires </a>
          </li>
          <li>
            <a href="/packages"> Services et Tarifs </a>
          </li>
          <li>
            <a href="/contact">Contactez-nous  </a>
          </li>
        </ul>
        <div className="links">
          {!localValue ? (
            <div className="link">
              <a className="button buttonblack1" href="/login">
                <span>Login</span>
              </a>
            </div>
           ) : ( 
            <div className="link">
              <a className="button buttonblack1" href="/" onClick={handleLogout} >
                <span>Logout</span>
              </a>
              
            </div>
           )} 

        </div>
      </div>
    </header>
  );
};

export default Header;
