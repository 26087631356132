// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-box {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.box {
  position: relative;
  width: 500px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0ddd5;
}

.close-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
}

.close-button.top-right {
  position: absolute;
  top: 10px;
  right: 10px;
}

.footer {
  margin-top: 20px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/Popup2/Popup2.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,8BAA8B;EAC9B,WAAW;EACX,YAAY;EACZ,MAAM;EACN,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,2CAA2C;EAC3C,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".popup-box {\n  position: fixed;\n  background: rgba(0, 0, 0, 0.5);\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 9999;\n}\n\n.box {\n  position: relative;\n  width: 500px;\n  padding: 20px;\n  background: #fff;\n  border-radius: 10px;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n  border: 1px solid #e0ddd5;\n}\n\n.close-button {\n  background: none;\n  border: none;\n  font-size: 16px;\n  cursor: pointer;\n  padding: 10px;\n}\n\n.close-button.top-right {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n}\n\n.footer {\n  margin-top: 20px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
