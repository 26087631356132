import React, { useState } from "react";
import config from "../../config";
import Popup2 from "../../Popup2/Popup2";

const Login_page = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const navigateToEvent = () => {
    window.location.href = '/event';
  };

  const submit = () => {
    localStorage.setItem("isLoggedIn", true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(config.REACT_APP_API_URL + 'auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });
      if (!response.ok) {
        setPopupMessage('Authentication failed: Incorrect username or password.');
      }
      const data = await response.json();
      if (data.token) {
        localStorage.setItem('token', data.token);
        navigateToEvent();
      } else {
        setPopupMessage('Authentication failed: Incorrect username or password.');
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setPopupMessage('Authentication failed: Incorrect username or password.');
      setShowPopup(true);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupMessage('');
  };

  return (
    <>
      <div className="container">
        <div className="contact login">
          <div className="form">
            <div className="right">
              <h1 className="page_title">
                <span>Login</span>
              </h1>
              <form onSubmit={handleSubmit}>
                <div className="form_group_bg">
                  <input 
                    type="text" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    placeholder="Name"
                  />
                  <div className="hover">
                    <span>Username</span>
                    <span>*</span>
                  </div>
                </div>
                <div className="form_group_bg">
                  <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    placeholder="Password"
                  />
                  <div className="hover">
                    <span>Password</span>
                    <span>*</span>
                  </div>
                </div>
                <div className="form_group_bg">
                  <a className="button buttonblack white">
                    <button onClick={submit} type="submit">Login</button>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
        {showPopup && (
          <Popup2 content={<>
            <p>{popupMessage}</p>
          </>} handleClose={closePopup} />
        )}
      </div>
    </>
  );
};

export default Login_page;
