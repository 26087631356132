import React, { useEffect, useState } from "react";
import "./Event.css";
import axios from "axios";
import Popup from "../../Popup/Popup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import config from "../../config";

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [eventName, setEventName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [website, setWebsite] = useState("");
  const [eventID, setEventID] = useState("");

  const togglePopup = (event) => {
    if (event) {
      setEventID(event.event_id);
      setEventName(event.event_name);
      setDescription(event.description);
      setStartDate(new Date(event.start_date));
      setEndDate(new Date(event.end_date));
      setWebsite(event.url);
    } else {
      // Clear fields if no event is provided (indicating a new event)
      setEventID(null);
      setEventName("");
      setDescription("");
      setStartDate(null);
      setEndDate(null);
      setWebsite("");
    }
    setIsOpen(!isOpen);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const saveData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in local storage.");
      return;
    }
    try {
      if (eventName.trim().length === 0 || description.trim().length === 0) {
        alert('Event name and description must be provided.');
        return;
      }
      
      let objItem = {
        event_name: eventName,
        description: description,
        start_date: startDate,
        end_date: endDate,
        url: website,
      };

      let apiUrl = config.REACT_APP_API_URL+"events/events";
      let method = "post";

      if (eventID) {
        apiUrl += `/${eventID}`;
        method = "put";
      }

      const response = await axios[method](apiUrl, objItem, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Data saved:", response);
      console.log("Data saved:", response.data);
      // Optionally, you can fetch events again to update the events list
      fetchEvents();
      setEventName("");
      setDescription("");
      setStartDate("");
      setEndDate("");
      setWebsite("");
      // Close the popup after saving data
      closePopup();
      alert("Event Created successfully");
    } catch (error) {
      console.error("Error saving data:", error);
      alert(" Event Creation failed due to following error", error);
    }
  };

  // delete data
  const deleteEvent = async (eventID) => {
    debugger;
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in local storage.");
        return;
      }
      await axios.delete(config.REACT_APP_API_URL+`events/events/${eventID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchEvents();
      alert("Event Deleted successfully");
    } catch (error) {
      console.error("Error deleting event:", error);
      alert("Error deleting event:", error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in local storage.");
        return;
      }
      const response = await axios.get(config.REACT_APP_API_URL +"events/events", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  return (
    <div className="container">
      <div class="tabe-btn-pos">
      <button class="add-btn-css" onClick={() => togglePopup()}>
        Add
      </button>
      </div>
      <div class="table-responsive">
      <table class="event-table-login">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nom de foire</th>
            <th>Domaine</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Site web</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event) => (
            <tr key={event.id}>
              <td>{event.event_id}</td>
              <td>{event.event_name}</td>
              <td>{event.description}</td>
              <td>{moment(event.start_date).format("L")}</td>
              <td>{moment(event.end_date).format("L")}</td>
              <td>
                <a href={event.website}>{event.url}</a>
              </td>
              <td className="btn-group1">
                <button className="btn-edit" onClick={() => togglePopup(event)}>
                  Edit
                </button>
                <button
                  className="btn-delete"
                  onClick={() => deleteEvent(event.event_id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      {isOpen && (
        <div className="popup">
          <div className="popup-container">
            <Popup
              content={
                <>
                  <div class="form-row">
                    <div class="form-column">
                      <label for="eventName">Nom de foire :</label>
                      <input
                        type="text"
                        id="eventName"
                        placeholder="Enter the event name"
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                      />
                    </div>
                    {/* <div class="form-column">
                      <label for="description" className="des">Description :</label>
                      <input
                        type="text"
                        id="description"
                        placeholder="Enter the domain"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div> */}
                  </div>
                  <div class="form-row">
                    <div class="form-column">
                      <label for="description" className="des">
                        Description :
                      </label>
                      <textarea
                        type="text"
                        id="description"
                        placeholder="Enter the domain"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-column">
                      <label for="startDate">Start Date :</label>
                      <DatePicker
                        className="datecss"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Enter the start date"
                      />
                    </div>
                    <div class="form-column">
                      <label for="endDate" className="endDate">
                        End Date :
                      </label>
                      <DatePicker
                        className="enddatecss"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        placeholderText="Enter the end date"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-column">
                      <label for="website">Site web :</label>
                      <input
                        type="text"
                        id="website"
                        placeholder="Enter the website URL"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </div>
                  </div>
                  <div style={{ position: "relative", top: "-4px" }} className="text-center-btn">
                    <button className="close" onClick={closePopup}>
                      close
                    </button>
                    <button className="Save buttonblack1" onClick={saveData}>
                      Save
                    </button>
                  </div>
                </>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EventsPage;
