import React from "react";

const Packages = () => {
  return (
    <>
      <div className="auto-container" style={{ marginTop: "50px" }}>
        <div className="sec-title text-center">
          <span className="title">Les forfaits de voyage</span>
          <h1>
            Choisissez la foire que vous intéressent et on vous obtiendra une
            lettre d’invitation personnalisé qui vous aidera à obtenir votre
            visa dans les plus brefs délais.{" "}
          </h1>
        </div>

        <div className="outer-box">
          <div className="row">
            <div
              className="pricing-block-two col-lg-6 col-md-6 col-sm-12 wow fadeInUp animated animated"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="inner-box">
                <div className="title">
                  <span className="icon flaticon-movie-tickets"></span>Services
                  supplémentaires disponibles sur demande
                </div>
                <div class="price-icon">
                  <div>
                    <i class="fas fa-home"></i>
                  </div>
                </div>

                <ul className="features">
                  <li className="true">
                    La mise à disposition d'un traducteur.
                  </li>
                  <li className="true">
                    Une journée du Shopping de votre choix.
                  </li>
                  <li className="true">
                    Une sortie en bateau dans le prestigieux Bosphore.
                  </li>
                  <li className="true">Excursion culinaire .</li>
                </ul>
              </div>
            </div>

            <div
              className="pricing-block-two col-lg-6 col-md-6 col-sm-12 wow fadeInUp animated animated"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="inner-box">
                <div className="title">
                  <span className="icon flaticon-movie-tickets"></span> Forfait
                  Single (1 personne) 
                </div>
                <div class="price-icon">
                  <div>
                    <i class="fas fa-home"></i>
                  </div>
                </div>
                <div className="price-box">
                  <h4 className="price">499.000 CFA</h4>
                </div>
                <ul className="features">
                  <li className="true">
                    4 nuitées dans un hôtel 4 étoiles avec petit déjeuner.
                  </li>
                  <li className="true">Transport depuis et vers l’aéroport.</li>
                  <li className="true">
                    Transport depuis et vers le centre de congrès.
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="pricing-block-two col-lg-6 col-md-6 col-sm-12 wow fadeInUp animated animated"
              data-wow-delay="400ms"
              style={{
                visibility: "visible",
                animationDelay: "400ms",
                animationName: "fadeInUp",
              }}
            >
              <div className="inner-box">
                <div className="title">
                  <span className="icon flaticon-movie-tickets"></span> Forfait
                  Double (2 personnes partageants la même chambre) 
                </div>
                <div class="price-icon">
                  <div>
                    <i class="fas fa-tags"></i>
                  </div>
                </div>
                <div className="price-box">
                  <h4 className="price">699.000 CFA</h4>
                </div>
                <ul className="features">
                  <li className="true">
                    4 nuitées dans un hôtel 4 étoiles avec petit déjeuner.
                  </li>
                  <li className="true">Transport depuis et vers l’aéroport.</li>
                  <li className="true">
                    Transport depuis et vers le centre de congrès.
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="pricing-block-two col-lg-6 col-md-6 col-sm-12 wow fadeInUp animated animated"
              data-wow-delay="1200ms"
              style={{
                visibility: "visible",
                animationDelay: "1200ms",
                animationName: "fadeInUp",
              }}
            >
              <div className="inner-box">
                <div className="title">
                  <span className="icon flaticon-movie-tickets"></span> Forfait
                  Triple (3 personnes partageants la même chambre)
                </div>
                <div class="price-icon">
                  <div>
                    <i class="fas fa-piggy-bank"></i>
                  </div>
                </div>
                <div className="price-box">
                  <h4 className="price">799.000 CFA</h4>
                </div>
                <ul className="features">
                  <li className="true">
                    4 nuitées dans un hôtel 4 étoiles avec petit déjeuner.
                  </li>
                  <li className="true">Transport depuis et vers l’aéroport.</li>
                  <li className="true">
                    Transport depuis et vers le centre de congrès.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Packages;
