// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.golden-button {
  background-color: #FFD700; /* Golden color */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease; /* Smooth transition */
}

.golden-button:hover {
  background-color: #FFC300; /* Slightly darker gold on hover */
  color: white;
}

.golden-button.disabled {
  background-color: #D3D3D3; /* Grey background when disabled */
  color: #A9A9A9; /* Grey text when disabled */
  cursor: not-allowed; /* Show not-allowed cursor */
  pointer-events: none; /* Disable interactions */
  opacity: 0.6; /* Reduce opacity */
}

  .required {
    color: red;
    margin-left: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Contact Page/Contact_page.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,iBAAiB;EAC5C,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,mBAAmB;EACnB,0EAA0E,EAAE,sBAAsB;AACpG;;AAEA;EACE,yBAAyB,EAAE,kCAAkC;EAC7D,YAAY;AACd;;AAEA;EACE,yBAAyB,EAAE,kCAAkC;EAC7D,cAAc,EAAE,4BAA4B;EAC5C,mBAAmB,EAAE,4BAA4B;EACjD,oBAAoB,EAAE,yBAAyB;EAC/C,YAAY,EAAE,mBAAmB;AACnC;;EAEE;IACE,UAAU;IACV,gBAAgB;EAClB","sourcesContent":[".golden-button {\n  background-color: #FFD700; /* Golden color */\n  color: white;\n  border: none;\n  padding: 10px 20px;\n  font-size: 16px;\n  cursor: pointer;\n  border-radius: 12px;\n  transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease; /* Smooth transition */\n}\n\n.golden-button:hover {\n  background-color: #FFC300; /* Slightly darker gold on hover */\n  color: white;\n}\n\n.golden-button.disabled {\n  background-color: #D3D3D3; /* Grey background when disabled */\n  color: #A9A9A9; /* Grey text when disabled */\n  cursor: not-allowed; /* Show not-allowed cursor */\n  pointer-events: none; /* Disable interactions */\n  opacity: 0.6; /* Reduce opacity */\n}\n\n  .required {\n    color: red;\n    margin-left: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
