import React, { useState } from "react";
import config from "../../config";
import "./Contact_page.css"; // Ensure your CSS file is properly imported

const Contact_page = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    if (!name || !surname || !email || !phone || !message) {
      alert('All fields are required.');
      return;
    }

    try {
      setButtonText("Sending");
      setIsSubmitting(true);

      let objItem = {
        name: name,
        surname: surname,
        email: email,
        phone: phone,
        message: message,
      };

      // Make API call to send form data
      const response = await fetch(
        config.REACT_APP_API_URL + "contact/contact-us",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(objItem),
        }
      );

      if (response.ok) {
        // Handle successful submission
        console.log("Form submitted successfully!");
        alert("Form submitted successfully!");
        // Reset form fields
        setName("");
        setSurname("");
        setEmail("");
        setPhone("");
        setMessage("");
      } else {
        // Handle error response
        console.error("Error submitting form:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setTimeout(() => { setButtonText("Submit");
        setIsSubmitting(false);}, 2000); // Hide the progress bar after 2 seconds
    }
  };

  return (
    <>
      <div className="container">
        <div className="contact" style={{ marginTop: "70px" }}>
          {/* <h1 className="page_title">
            <span>Contactez-nous  </span>
          </h1> */}
          <div className="form">
            <div className="left">
              <h3></h3>
              <div class="black-form">
                <div>
                  <i class="fas fa-question"></i>
                </div>
                <p>
                  General Inquiries:<br></br> <span>info@consultidil.com</span>
                </p>
              </div>
              <div class="black-form">
                <div>
                  <i class="fab fa-whatsapp"></i>
                </div>
                <p>
                  WhatsApp Inquiries: <br></br>
                  <span> +225 0702950643</span>
                </p>
              </div>
              <div class="black-form1">
                <h5>Follow Me: </h5>
             
                 <a href="https://www.instagram.com/consultidil/"  target="blank" class="bg-color-ins"><i class="fab fa-instagram"></i></a> 
                 <a href="https://wa.me/+2250702950643" target="blank" class="bg-color-whats"><i class="fab fa-whatsapp"></i></a> 
            
              </div>
              <div
                className="left_icons"
                style={{
                  display: "grid",
                  gridAutoFlow: "column",
                  maxWidth: "82px",
                }}
              >
                <a
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  href="#"
                >
                  <img src="storage/temporary/1697521225614251644.svg" alt="" />
                </a>
                <a
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  href="#"
                >
                  <img src="storage/temporary/169536525263405099.svg" alt="" />
                </a>
                <a
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  href="#"
                >
                  <img src="storage/temporary/1695365289733011390.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="right">
              <h2>Get In Touch</h2>
              <form>
                <div className="form_group_sm">
                  <input
                    name="name"
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div className="hover">
                    <span>Name</span>
                    <span className="required">*</span> {/* Red asterisk for required field */}
                  </div>
                </div>
                <div className="form_group_sm">
                  <input
                    name="surname"
                    type="text"
                    placeholder="Surname"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                  />
                  <div className="hover">
                    <span>Surname</span>
                    <span className="required">*</span> {/* Red asterisk for required field */}
                  </div>
                </div>
                <div className="form_group_sm">
                  <input
                    name="email"
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="hover">
                    <span>Email</span>
                    <span className="required">*</span> {/* Red asterisk for required field */}
                  </div>
                </div>
                <div className="form_group_sm">
                  <input
                    name="phone"
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <div className="hover">
                    <span>Phone</span>
                    <span className="required">*</span> {/* Red asterisk for required field */}
                  </div>
                </div>
                <div className="form_group_bg">
                  <textarea
                    name="message"
                    placeholder="Message"
                    rows="6"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  <div className="hover">
                    <span>Message</span>
                    <span className="required">*</span> {/* Red asterisk for required field */}
                  </div>
                </div>
                <div className="form_group_bg">
                <button
    className={`golden-button ${isSubmitting ? 'disabled' : ''}`}
    type="submit"
    onClick={handleSubmit}
    disabled={isSubmitting}
  >
    {buttonText}
  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact_page;
