import React from 'react';
import './Popup2.css';

const Popup2 = ({ content, handleClose }) => {
    return (
        <div className="popup-box">
            <div className="box">
                {/* Top right close button */}
                <button className="close-button top-right" onClick={handleClose}>X</button>
                <br></br>
                {content}
                {/* Footer close button */}
                <div className="footer">
                    <button className="close-button" onClick={handleClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default Popup2;
