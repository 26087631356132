import React, { useEffect, useState } from "react";
import "./withOutLogin.css";
import axios from "axios";
import moment from "moment";
import config from "../../config";

const Without_Login_Event = () => {
  const [events, setEvents] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const closePopup = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(config.REACT_APP_API_URL +"events/public_events");
        console.log(response.data);
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);
  return (
    <div
      class="container-event"
      style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
    >
      {events.map((event, index) => (
        <div
          class="event-card"
          key={index}
          style={{
            minWidth: "45%",
            maxWidth: "70%",
            minHeight: "150px",
            // margin: "20px",
           
           
          }}
        >
          <div class="card-body">
            <div class="row">
    
              <div class="cell event-title col-12" >
                {event.event_name}
              </div>
              <div class="col-12">
              <div class="event-desc">{event.description}</div>
              </div>
              <div class="col-6">
             <div class="event-date"><h5>Start Date</h5><p> {moment(event.start_date).format("L")}</p></div>
             </div>
             <div class="col-6">
             <div class="event-date"><h5>End Date</h5><p> {moment(event.end_date).format("L")}</p></div>
            </div>
           
            
            </div>
            <div class="event-url">
              <a href={event.url} target="_blank">
                  {event.url}
                </a>
                </div>
            
            {/* <div class="row">
              <div class="cell">
                <span style={{ marginRight: "5px" }}>
                  {moment(event.start_date).format("L")}
                </span>
                <span>-</span>
                <span style={{ marginLeft: "5px" }}>
                  {moment(event.end_date).format("L")}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="cell">
                <a href={event.url} target="_blank">
                  {event.url}
                </a>
              </div>
            </div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Without_Login_Event;
