import React, { useState, useEffect } from "react";
import Image4 from "../../assets/photos/8.jpg";
import Image3 from "../../assets/photos/7.jpg";
import Image5 from "../../assets/photos/6.jpg";
import Image6 from "../../assets/photos/5.jpg";
import Image7 from "../../assets/photos/4.jpg";
import Image8 from "../../assets/photos/3.jpg";
import Image9 from "../../assets/photos/2.jpg";
import Image2 from "../../assets/photos/1.jpg";
import { useNavigate } from "react-router-dom";

const Main_content = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    { url: Image4 },
    { url: Image3 },
    { url: Image5 },
    { url: Image6 },
    { url: Image7 },
    { url: Image8 },
    { url: Image9 },
    { url: Image2 },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [slides.length]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    );
  };

  return (
    <>
      <div className="container1">
        <div className="carousel">
          <div className="slide">
            <img
              src={slides[currentIndex].url}
              alt=""
              style={{
                width: "100%",
                objectFit: "cover",
                overflow: "hidden",
                height: "100%",
              }}
            />
          </div>
          {slides.length > 1 && (
            <>
              <button className="arrow prev" onClick={prevSlide}>
                &#10094;
              </button>
              <button className="arrow next" onClick={nextSlide}>
                &#10095;
              </button>
            </>
          )}
        </div>

        <div className="about home-page-about">
          <div
            className="about_content"
            style={{ gap: 0, flexDirection: "column" }}
          >
            <div className="home-ads">
              <p>
                Voyagez en toute sécurité et confiance. Vous serez très bien
                pris en charge dès votre atterrissage à Istanbul jusqu'à votre
                départ. Il vous suffit de choisir le salon qui vous intéresse et
                nous nous occupons des détails.
              </p>
              <a href="/contact">
                Contact Now
              </a>
            </div>
            <div className="left">
              <br />
              <p>
                Les foires et les forums du business qui se passent en Turquie
                vous intéressent et vous voulez y participer ? Vous voulez
                savoir quelles sont les foires organisés / recommandés par le
                ministère de commerce turque et qui rassemblent les plus
                importants acteurs économiques du pays sous un seul toi ? Vous
                êtes un homme d’affaires occupé et vous voulez qu’on organise
                votre voyage de travail du A à Z en toute sécurité ?
              </p>
              <p>
                Vous avez trouvé la bonne adresse ! Avec Consult Idil on vous
                offre tout ce dont vous avez besoin et bien plus encore ! Avec
                une équipe professionnelle et attentive qui accueil vos demandes
                en Abidjan et à Istanbul, voyagez en toute confiance et faites
                les meilleurs deals !
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main_content;
