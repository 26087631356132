// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-event {
    flex-direction: row;
    gap: 25px; 
    padding: 10px; 
    /* max-width: 98%;  */
    width: 100%;
    margin: 20px auto; 
  }
  .home-ads {
    background: rgb(66, 66, 66);
    background: linear-gradient(135deg, rgba(66, 66, 66, 1) 42%, rgba(230, 184, 11, 1) 42%, rgba(230, 184, 11, 1) 42%);
    padding: 50px 30px;
    color: #fff;
    border-radius: 0 50px 0 50px;
    position:relative
}
.home-ads::after {
  content: '';
  position: absolute;
  top: 8px;
  right: 0px;
  left: 0;
  width: 96%;
  height: 88%;
  border: 2px solid #fff;
  margin: auto;
  border-radius:0 50px 0 50px;
  pointer-events: none;
  z-index: 0;
}
  .row {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    margin-bottom: 5px;
    
  }
  .event-desc , .event-date p{
    font-size: 16px;
    color: #777;
    padding: 0 0 10px;
}
.event-date h5 {
  font-size: 13px;
  font-weight: 600;
  color: #222;
}
.event-url a {
  display: block;
  width: fit-content;
  color: #be9b16;
}
  .cell {
    margin-left: 10px;
    font-size: 20px;
    line-height: 1;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/UserWithoutLoginEvent/withOutLogin.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,SAAS;IACT,aAAa;IACb,qBAAqB;IACrB,WAAW;IACX,iBAAiB;EACnB;EACA;IACE,2BAA2B;IAC3B,kHAAkH;IAClH,kBAAkB;IAClB,WAAW;IACX,4BAA4B;IAC5B;AACJ;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,OAAO;EACP,UAAU;EACV,WAAW;EACX,sBAAsB;EACtB,YAAY;EACZ,2BAA2B;EAC3B,oBAAoB;EACpB,UAAU;AACZ;EACE;IACE,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,kBAAkB;;EAEpB;EACA;IACE,eAAe;IACf,WAAW;IACX,iBAAiB;AACrB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,cAAc;AAChB;EACE;IACE,iBAAiB;IACjB,eAAe;IACf,cAAc;EAChB","sourcesContent":[".container-event {\r\n    flex-direction: row;\r\n    gap: 25px; \r\n    padding: 10px; \r\n    /* max-width: 98%;  */\r\n    width: 100%;\r\n    margin: 20px auto; \r\n  }\r\n  .home-ads {\r\n    background: rgb(66, 66, 66);\r\n    background: linear-gradient(135deg, rgba(66, 66, 66, 1) 42%, rgba(230, 184, 11, 1) 42%, rgba(230, 184, 11, 1) 42%);\r\n    padding: 50px 30px;\r\n    color: #fff;\r\n    border-radius: 0 50px 0 50px;\r\n    position:relative\r\n}\r\n.home-ads::after {\r\n  content: '';\r\n  position: absolute;\r\n  top: 8px;\r\n  right: 0px;\r\n  left: 0;\r\n  width: 96%;\r\n  height: 88%;\r\n  border: 2px solid #fff;\r\n  margin: auto;\r\n  border-radius:0 50px 0 50px;\r\n  pointer-events: none;\r\n  z-index: 0;\r\n}\r\n  .row {\r\n    display: flex;\r\n    flex-wrap: wrap; \r\n    justify-content: center;\r\n    margin-bottom: 5px;\r\n    \r\n  }\r\n  .event-desc , .event-date p{\r\n    font-size: 16px;\r\n    color: #777;\r\n    padding: 0 0 10px;\r\n}\r\n.event-date h5 {\r\n  font-size: 13px;\r\n  font-weight: 600;\r\n  color: #222;\r\n}\r\n.event-url a {\r\n  display: block;\r\n  width: fit-content;\r\n  color: #be9b16;\r\n}\r\n  .cell {\r\n    margin-left: 10px;\r\n    font-size: 20px;\r\n    line-height: 1;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
