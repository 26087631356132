import React, { useState, useEffect } from "react";
import Image4 from "../../assets/photos/idil about us first menu item.jpg";
import Image5 from "../../assets/photos/sublimeci.jpg";
import Image6 from "../../assets/photos/Image 10.jpg";
import Image7 from "../../assets/photos/Image 11.jpg";
import Image8 from "../../assets/photos/Image 12.jpg";
// import Image9 from "../../assets/photos/Image 1.jpeg";

const About = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    { url: Image4 },
    { url: Image5 },
    { url: Image6 },
    { url: Image7 },
    { url: Image8 },
    // { url: Image9 },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [slides.length]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    );
  };

  return (
    <>
      <div className="container">
        <div className="about about-page">
          <h1 className="about_title">
            <span>
              <font style={{ verticalAlign: "inherit" }}>
                <font style={{ verticalAlign: "inherit" }}>À propos</font>
              </font>
            </span>
          </h1>
          <div
            className="about_content"
            style={{ gap: 0, flexDirection: "column" }}
          >
            <div className="left">
              <p>
                Je m’appelle Idil Ozdogan, j’ai 35 ans et actuellement j’habite
                en Côte d’Ivoire. Ma famille et moi avons déménager à Abidjan en
                2022 et j’ai eu l’opportunité de joindre l’équipe local d’une
                entité diplomatique. Ça m’apprit une année pour bien maitriser
                la culture et le mode de fonctionnement de la société
                ivoirienne. J’ai rencontré des gens généreux qui m’ont ouvert
                les yeux sur plusieurs aspects de la vie professionnelle et les
                opportunités en Côte d’Ivoire.
              </p>
              <p>
                Je m’appelle Idil Ozdogan, j’ai 35 ans et actuellement j’habite
                en Côte d’Ivoire. Après l’obtention de mon master en Langues
                Appliquées de la faculté des lettres de Manouba, Tunisie j’ai
                commencé à travailler dans le domaine de l’hôtellerie de luxe
                comme étant responsable commercial. Une expérience qui a duré
                huit ans et m’a fait vivre entre plusieurs pays et travailler
                avec des personnes issues de tout coins du monde. Pendant COVID
                j’ai eu un grand désire de changer ma carrière et devenir plutôt
                un motivateur et un mentor pour mes collègues donc j’ai eu mon
                CIPD En apprentissage et développent de ICS en Ecosse. Cependant
                nous avons dû déménager à Abidjan en 2022. Je disais toujours si
                tu ne travailles pas las où tu vis tu n’arrives jamais à
                comprendre le pays.
              </p>

              <p>
                Par conséquent quand j’ai eu l’opportunité de joindre l’équipe
                local d’une ambassade j’ai sauté sur l’occasion. Ça m’apprit une
                année pour bien maitriser la culture et le mode de
                fonctionnement de la société ivoirienne. J’ai rencontré des gens
                généreux qui m’ont ouvert les yeux sur plusieurs aspects de la
                vie professionnelle et les opportunités en Côte d’Ivoire.
              </p>

              <p>
                J’ai rencontré plusieurs hommes d’affaires qui sont intéressés
                par le marché turc et qui veulent participer au foires et salons
                organisés à Istanbul, mais ils veulent toujours être sur qu’il
                s’agit d’un événement vrai. Là j’ai commencé à voir clairement
                l’importance de créer Consult Idil.
              </p>

              <p>
                Venant d’un background hôtelier je m’efforce à fournir une
                expérience parfaite pour mes clients. Et en travaillant dans la
                diplomatie économique j’ai réussi à être plus proche des
                entrepreneurs et comprendre leurs attentes et leurs besoins de
                ce type d’événements.
              </p>

              <p>
                L’idée de créer cette boite de consultation est la conclusion
                naturelle de ma carrière professionnelle qui rassemblait le
                tourisme et la diplomatie économique. Après plus de 12 ans
                d’expérience dans ces domaines j’ai collaboré avec des amis qui
                résident à Istanbul et qui vont être disponible 24/7 pour nos
                clients. On va faciliter votre participation par fournir un
                hébergement confortable, un transport fiable et tout autre
                service qui pourra améliorer votre voyage d’affaire.
              </p>
            </div>
          </div>
          <div className="carousel mb-6">
            <div className="slide">
              <img
                src={slides[currentIndex].url}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  overflow: "hidden",
                }}
              />
            </div>
            {slides.length > 1 && (
              <>
                <button className="arrow prev" onClick={prevSlide}>
                  &#10094;
                </button>
                <button className="arrow next" onClick={nextSlide}>
                  &#10095;
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
