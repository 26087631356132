// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonblack1{
    border: 1px solid #e6b80b;
    cursor: pointer;
    overflow: auto;
    position: relative;
    text-align: center;
    transition: all .5s cubic-bezier(.25,.46,.45,.94);
    z-index: 5
}

 .buttonblack1:before {
    background: #fffde3;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 50%;
    transform: scaleX(0) translateY(-50%);
    transform-origin: right;
    transition: transform 1s cubic-bezier(.25,.46,.45,.94);
    width: 100%;
    z-index: -1
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,iDAAiD;IACjD;AACJ;;CAEC;IACG,mBAAmB;IACnB,WAAW;IACX,cAAc;IACd,YAAY;IACZ,OAAO;IACP,kBAAkB;IAClB,QAAQ;IACR,qCAAqC;IACrC,uBAAuB;IACvB,sDAAsD;IACtD,WAAW;IACX;AACJ","sourcesContent":[".buttonblack1{\r\n    border: 1px solid #e6b80b;\r\n    cursor: pointer;\r\n    overflow: auto;\r\n    position: relative;\r\n    text-align: center;\r\n    transition: all .5s cubic-bezier(.25,.46,.45,.94);\r\n    z-index: 5\r\n}\r\n\r\n .buttonblack1:before {\r\n    background: #fffde3;\r\n    content: \"\";\r\n    display: block;\r\n    height: 100%;\r\n    left: 0;\r\n    position: absolute;\r\n    top: 50%;\r\n    transform: scaleX(0) translateY(-50%);\r\n    transform-origin: right;\r\n    transition: transform 1s cubic-bezier(.25,.46,.45,.94);\r\n    width: 100%;\r\n    z-index: -1\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
