/* eslint-disable react/jsx-pascal-case */
import "./App.css";
import About from "./Components/About_Page/About.jsx";
import Contact_page from "./Components/Contact Page/Contact_page.jsx";
import Footer from "./Components/Footer/Footer.jsx";
import Header from "./Components/Header/Header.jsx";
import Login_page from "./Components/Login_Page/Login_page.jsx";
import Main_content from "./Components/Main_content/Main_content.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Events_page from "./Components/Up_coming_events/Events_page.jsx";
import Packages from "./Components/Pack/Packages.jsx";
import Without_Login_Event from "./Components/UserWithoutLoginEvent/Without_Login_Event.jsx";

function App() {
  return (
    <div className="">
      <Header />
      <Router>
        <div>
          <Routes>
            <Route exact path="/" element={<Main_content />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact_page />} />
            <Route path="/login" element={<Login_page />} />
            <Route path="/event" element={<Events_page />} />
            <Route path="/Publicevents" element={<Without_Login_Event />} />
            <Route path="/packages" element={<Packages />} />
          </Routes>
        </div>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
